import Homepage from "../src/pages/Homepage.jsx";
import ContactUs from "./pages/ContactUs.jsx";
import Overview from "./pages/Overview.jsx";
import Projects from "./pages/Projects.jsx";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/projects" element={<Projects/>} />
        <Route path="/overview" element={<Overview/>} />
        <Route path ="/contact-us" element={<ContactUs/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
