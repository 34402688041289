import Carousel from "react-bootstrap/Carousel";
import { useEffect } from "react";
import "../css/projects.css";
import import1 from "../assets/1.jpg";
import import2 from "../assets/2.jpg";
import import3 from "../assets/3.jpg";
import import4 from "../assets/4.jpg";
import import5 from "../assets/5.jpg";
import import6 from "../assets/6.jpg";
import import7 from "../assets/7.jpg";
import import8 from "../assets/8.jpg";
import import9 from "../assets/9.jpg";
import import10 from "../assets/10.jpg";
import import11 from "../assets/11.jpg";
import import12 from "../assets/12.jpg";
import import13 from "../assets/13.jpg";
import import14 from "../assets/14.jpg";
import import15 from "../assets/15.jpg";
import import16 from "../assets/16.jpg";
import import17 from "../assets/17.jpg";
import import18 from "../assets/18.jpg";
import import19 from "../assets/19.jpg";
import import20 from "../assets/20.jpg";
import import21 from "../assets/21.jpg";
import import22 from "../assets/22.jpg";
import import23 from "../assets/23.jpg";
import import24 from "../assets/24.jpg";
import import25 from "../assets/25.jpg";

export const GalleryProjects = () => {
  useEffect(()=>{
    window.scroll(0,0)
  })
  return (
    <div className="projectsImgConatainer">
      <h1>Gallery</h1>
      <Carousel>
        <Carousel.Item>
          <img className="carouselImg" src={import1} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import2} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import3} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import4} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import5} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import6} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import7} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import8} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import9} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import10} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import11} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import12} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import13} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import14} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import15} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import16} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import17} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import18} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import19} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import20} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import21} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import22} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import23} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import24} alt="galleryImage" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="carouselImg" src={import25} alt="galleryImage" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export const ProjectsList = () => {
  return (
    <div className="projectsConatainer">
      <h1>Our Projects</h1>
      <table>
        <tr>
          <th>Project Type</th>
          <th>Duration</th>
          <th>Region</th>
        </tr>
        <tr>
          <td>IMPLS (NFS)</td>
          <td>2021 - 2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Data N/W Cabling (NFS)</td>
          <td>2022 - 2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>MSAN (Indian Army)</td>
          <td>2022 - 2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>UBR - Airtel</td>
          <td>2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Tower Foundation - Civil</td>
          <td>2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Power Grid (NEC)</td>
          <td>2023 - 2024</td>
          <td>India - Jammu and Kashmir</td>
        </tr>
        <tr>
          <td>GOF - NMS (HFCL)</td>
          <td>2020 - 2021</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Survilence (NFS)</td>
          <td>2021 - 2022</td>
          <td>India</td>
        </tr>
        <tr>
          <td>DCN (BEETEL)</td>
          <td>2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Tower Foundation - Civil (BSNL)</td>
          <td>2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Solar Project (Airtel)</td>
          <td>2023</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Solar Project - Civil (VEDANG)</td>
          <td>2023-2024</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Surveying - I&C (BSNL)</td>
          <td>2024</td>
          <td>India</td>
        </tr>
        <tr>
          <td>Equipment Earthing (NFS)</td>
          <td>2024</td>
          <td>India</td>
        </tr>
        <tr>
          <td>N/W - I&C, Cable Tray, Wiring (Indian Army)</td>
          <td>2024</td>
          <td>India</td>
        </tr>
        <tr>
          <td>PAC & Split AC I&C (Indian Army)</td>
          <td>2024</td>
          <td>India</td>
        </tr>
      </table>
    </div>
  );
};
