import React, { useEffect } from "react";
import "../css/homepage.css";

import CardImg1 from "../assets/cardImg1.jpg";
import philImg from "../assets/philImg.jpg";
import EngImg from "../assets/imgEng.webp";
import Banner1 from "../assets/bannerImg1.jpg";
import Banner2 from "../assets/bannerImg2.jpg";

export const HeroComponent = () => {

  useEffect(()=>{
    window.scroll(0,0)
  })
  return (
    <div className="heroContainer">
      <div className="heroContainerBox">
        <img
          width="100%"
          height="100%"
          style={{ objectFit: "cover" }}
          // autoPlay
          // muted
          // loop
          // playsInline
          // preload="auto"
          src={Banner2}
          alt="banner"
        ></img>
      </div>
      <div className="tagLine">
        <div className="titleMain">SUKHNAG Infra Pvt ltd</div>
        <div>Lets Excel Everywhere</div>
        <div className="btn">
          <a
            style={{ textDecoration: "none", color: "white" }}
            href="mailto:umer@sukhnaginfra.com"
          >
            Book Online
          </a>
        </div>
      </div>
    </div>
  );
};

export const AboutUs = () => {
  return (
    <div className="aboutUsContainer">
      Our Services
      <div className="cardHome">
        <div className="imgConatiner">
          <img className="imgCard" src={CardImg1} alt="card" />
        </div>
        <div className="cardInfo">
          <ul>
            <div className="servicesHeading">Networking</div>
            <li>
              Network equipment I&C(Routers,Switch,ENB,RRH,Antena,Microwave
              links)
            </li>
            <li>Network structure cabling.</li>

            <li>OLT I&C</li>
            <li>OFC</li>
          </ul>
          <ul>
            <div className="servicesHeading">Infra</div>
            <li>UPS,SMPS,Battery bank I&C</li>
            <li>Area lighting works,Street lighting work.</li>
            <li>
              All types of power and distribution LT/HT pole work,cabling with
              Special end termination work.
            </li>
            <li>Substation I&C</li>
            <li>Solar(On grid/Off-grid)I&C</li>
            <li>Power distribution panels(I&C,Automation)</li>
          </ul>
          <div className="servicesHeading">Construction</div>

          <ul>
            <li>Tower Foundation</li>
            <li>Solar Foundation</li>
            <li>Commercial and Residental Buildings</li>
          </ul>
        </div>
      </div>
      Our Philosophy
      <div className="cardHome">
        <div className="imgConatinerPhil">
          <img className="philImg" src={philImg} alt="card" />
          <div className="textPhilContainer">
            <div className="textPhil">
              We aim to build beautiful, progressive buildings that make you
              comfortable and while protecting the environment.
            </div>
          </div>
        </div>
      </div>
      <div className="cardHome m1">
        <div className="imgConatinerPhil">
          <div className="heroContainerBox">
            <img
              width="100%"
              height="100%"
              style={{ objectFit: "cover", opacity: ".6" }}
              src={Banner1}
              alt="banner"
            ></img>
          </div>
          <div className="textPhilContainer">
            <div className="textPhil t1">
              <div className="missionTag"> Our Mission</div>
              <br />
              Sukhnag believes that electricity and communication is the
              cornerstone of a high quality life.There will be a never ending
              for products and services to harness and maximize its full
              potential and use. In doing so we will be the best in class
              provider of services.
            </div>
          </div>
        </div>
      </div>
      <div className="titleHomepage">
        Engineering Services by Sukhnag Infra Opc Pvt Ltd
      </div>
      <div className="cardHome p1">
        <div className="imgConatiner h1">
          <img className="imgCard" src={EngImg} alt="card" />
        </div>
        <div className="cardInfo">
          Structural Engineering
          <ul>
            <li>
              Our structural engineering services cover a wide range of
              projects, from residential and commercial buildings,Towers and
              other infrastructure. We use the latest technology and software to
              ensure your project is safe, reliable, and cost-effective.
            </li>
          </ul>
          Electrical,Communication Engineering
          <ul>
            <li>
              We offer comprehensive electrical,Telecom engineering services
              including design, analysis, Installation and testing. Our team of
              experienced engineers has the expertise to take your project from
              conception to completion, ensuring it meets all industry standards
              and regulations.
            </li>
          </ul>
          Project Development
          <ul>
            <li>
              If you have an idea for a new project, our team of experts can
              help you bring it to market. From concept development to
              prototyping and testing, we work with you every step of the way to
              ensure your projects is a success.
            </li>
          </ul>
          Project Management
          <ul>
            <li>
              Our project management services can help ensure your engineering
              project is delivered on time and within budget. We work closely
              with you to develop a project plan, manage resources, and oversee
              all aspects of the project from start to finish.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
