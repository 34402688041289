import React from 'react';
import { ContactInfo } from '../components/ContactUsComponents';
import Header from '../components/Header';
import Footer from '../components/Footer';

const ContactUs = () => {
    return (
        <div>
            <Header/>
            <ContactInfo/>
            <Footer/>
            
        </div>
    );
};

export default ContactUs;