import React, { useState } from "react";
import "../css/header.css";
import Logo from "../assets/logo.webp";
import MenuIcon from "../assets/menuIcon.png";
import { Link } from "react-router-dom";

const Header = () => {
  const [showMenu, setMenu] = useState(false);
  function handleMenu() {
    setMenu(!showMenu);
  }

  const UnderLined = ({ location, tabName }) => {
    if (window.location.pathname === location)
      return (
        <div className="tab tabUnderlined">
          <Link to={location}>{tabName}</Link>
        </div>
      );
    else
      return (
        <div className="tab">
          <Link to={location}>{tabName}</Link>
        </div>
      );
  };
  const MenuComp = () => (
    <>
      <div className="optionsMenuBox">
        <div className="pMobile">
          <UnderLined tabName={"Home"} location={"/"} />
          <UnderLined tabName={"Projects"} location={"/projects"} />
          <UnderLined tabName={"Overview"} location={"/overview"} />
          <UnderLined tabName={"Contact Us"} location={"/contact-us"} />
        </div>
      </div>
    </>
  );
  return (
    <>
      <div className="headerContainer">
        <div className="headerOptions">
          <div className="logoContainer">
            <Link to={"/"}>
              <img className="logoImg" src={Logo} alt="logo" />
            </Link>
          </div>
          <UnderLined tabName={"Home"} location={"/"} />
          <UnderLined tabName={"Projects"} location={"/projects"} />
          <UnderLined tabName={"Overview"} location={"/overview"} />
          <UnderLined tabName={"Contact Us"} location={"/contact-us"} />
        </div>
      </div>
      <div className="headerMobile">
        <div className="headerMobileOptions">
          <div className="logoContainer">
            <Link to={"/"}>
              <img className="logoImg" src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="menuIconContainer">
            <img
              className="menuIconImg"
              src={MenuIcon}
              onClick={handleMenu}
              alt="menuIcon"
            />
          </div>
        </div>
        {showMenu ? <MenuComp /> : null}
      </div>
    </>
  );
};

export default Header;
