import React from "react";
import {
  AboutUs,
  HeroComponent,
} from "../components/HomepageComponets";
import Header from "../components/Header";
import Footer from "../components/Footer";
const Homepage = () => {
  return (
    <div>
      <Header />
      <HeroComponent />
      <AboutUs />
      <Footer />
    </div>
  );
};

export default Homepage;
