import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Companyinfo } from '../components/OverviewComponents';

const Overview = () => {
    return (
        <div>
           <Header/>
           <Companyinfo/>
           <Footer/> 
        </div>
    );
};

export default Overview;