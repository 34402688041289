import "../css/overview.css";
import { useEffect } from "react";
import AirtelLogo from "../assets/airtel-logo-white-text-vertical.jpg";
import Iarmy from "../assets/indianarmy.png";
import Ceekay from "../assets/ceekay.png";
import Indus from "../assets/indus.svg";
import Vedang from "../assets/vedang-logo.png";
import Hfcl from "../assets/HFCL_White.png";
import Beetel from "../assets/beetel.png";
import Quess from "../assets/quessbluesvg.svg";
import HighlitedImg1 from "../assets/1707376523800.jpg";
import HighlitedImg2 from "../assets/1707376523835[89].jpg";
import HighlitedImg3 from "../assets/1707376523719.jpg";
import HighlitedImg4 from "../assets/1707376523743.jpg";

export const Companyinfo = () => {

  useEffect(()=>{
    window.scroll(0,0)
  })
  return (
    <div className="overviewContainer">
      <h1>Company vision</h1>
      <div className="visionTxt">
        Sukhnag believes that electricity and communication is the cornerstone
        of a high quality life.There will be a never ending for products and
        services to harness and maximize its full potential and use.In doing so
        we will be the best in class provider of services.
      </div>
      <h1>Highlighted Projects</h1>
      <div className="containerHprojects">
        <div className="imgCardHighlightedBox">
          <div className="projectsHImgContainer">
            <img className="hImg" src={HighlitedImg1} alt="projects" />
          </div>
          <div>IPMPLS - NFS Project</div>
        </div>
        <div className="imgCardHighlightedBox">
          <div className="projectsHImgContainer">
            <img className="hImg" src={HighlitedImg2} alt="projects" />
          </div>
          <div>DCN - NFS Project</div>
        </div>
        <div className="imgCardHighlightedBox">
          <div className="projectsHImgContainer">
            <img className="hImg" src={HighlitedImg3} alt="projects" />
          </div>
          <div>NEC - PGCIL Project</div>
        </div>
        <div className="imgCardHighlightedBox">
          <div className="projectsHImgContainer">
            <img className="hImg" src={HighlitedImg4} alt="projects" />
           
          </div>
          <div>Tower Foundation BSNL 4G Saturation</div>
        </div>
      </div>

      <h1>Top Customers</h1>
      <div className="customersSection">
        <div className="logoCustomersContainer">
          <img src={AirtelLogo} className="customerLogo" alt="logo" />
        </div>
        <div className="logoCustomersContainer">
          <img src={Indus} className="customerLogo" alt="logo" />
        </div>{" "}
        <div className="logoCustomersContainer">
          <img src={Iarmy} className="customerLogo" alt="logo" />
        </div>{" "}
        <div className="logoCustomersContainer">
          <img src={Beetel} className="customerLogo" alt="logo" />
        </div>{" "}
        <div className="logoCustomersContainer">
          <img src={Hfcl} className="customerLogo" alt="logo" />
        </div>{" "}
        <div className="logoCustomersContainer">
          <img
            src={Vedang}
            style={{ background: "white" }}
            className="customerLogo"
            alt="logo"
          />
        </div>{" "}
        <div className="logoCustomersContainer">
          <img src={Ceekay} className="customerLogo" alt="logo" />
        </div>
        <div className="logoCustomersContainer">
          <img
            src={Quess}
            style={{ background: "white" }}
            className="customerLogo"
            alt="logo"
          />
        </div>
      </div>
      <h1>Company Information</h1>
      <div>CIN NO: U44201JK2022OPC013748</div>
      <div>Gst: 01ABJCS2536A1ZY</div>
    </div>
  );
};
